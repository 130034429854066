
import { ChevronsUpDownIcon, CreditCardIcon, LoaderIcon, MoreVertical, RefreshCcwIcon, UserPenIcon } from "lucide-react"

import { Link } from "react-router-dom"
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar"
import { Button } from "../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem, DropdownMenuTrigger
} from "../../components/ui/dropdown-menu"
import { Switch } from "../../components/ui/switch"
import { cn } from "../../lib/utils"

export const columns = (onEditBank, toggleBankStatus, onGetBankDetails, onUpdateBalance, isUpdating, isChangingStatus) => [
  {
    accessorKey: "name",
    label: 'نام بانک',
    header: ({ column }) => {
      return (
        <Button
          className="w-[200px] justify-start p-0"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          نام بانک
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const {name, id, icon} = row.original;
      return <div className="flex items-center gap-x-2">
        <Avatar>
          <AvatarImage src={`${process.env.REACT_APP_BASE_FILE_URL}/${icon}`} />
          <AvatarFallback className='text-gray-800'>{name.substring(0, 1)}</AvatarFallback>
        </Avatar>
        <Link to={`/edit-bank?bankId=${id}`}>{name}</Link>
      </div>
    },
  },
  {
    accessorKey: "tranasactionLimit",
    label: 'محدودیت تراکنشی',
    header: ({ column }) => {
      return (
        <Button
          className="justify-start p-0"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          محدودیت تراکنشی
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.tranasactionLimit;
      return <div className="flex items-center gap-x-2">
              <span className="!text-gray-800">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
             </div>
    },
  },
  {
    accessorKey: "isActive",
    label: 'وضعیت (فعال/غیرفعال)',
    header: ({ column }) => {
      return (
        <Button
          className="justify-start p-0"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          وضعیت (فعال/غیرفعال)
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const isActive = row.getValue("isActive")
      const id = row.original.id;
      return isChangingStatus === id ? <LoaderIcon className="w-4 h-5 animate-spin" /> :<Switch className="ml-auto" checked={isActive} onCheckedChange={() => toggleBankStatus(row.original.id)} />
    },
  },
  {
    accessorKey: "accountBalanceAmount",
    label: 'موجودی',
    header: ({ column }) => {
      return (
        <Button
          className="justify-start p-0"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          موجودی
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.accountBalanceAmount;
      const id = row.original.id;
      return <div className="flex items-center gap-x-2">
              <span className="!text-gray-800">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
              <Button variant="ghost" size="icon" className="!m-0" onClick={() => onUpdateBalance(row.original.id)}>
                <RefreshCcwIcon className={cn(isUpdating === id ? "animate-spin" : "", "!m-0 h-4 w-4")} />
              </Button>
             </div>
    },
  },
  {
    accessorKey: "updateDate",
    label: 'آخرین بروزرسانی موجودی',
    header: ({ column }) => {
      return (
        <Button
          className="justify-start p-0"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          آخرین بروزرسانی موجودی
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.updateDate;
      return <div dir="ltr" className="flex items-center gap-x-2 justify-end">
              <span className="!text-gray-800">{value}</span>
             </div>
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const bank = row.original

      return (
        <DropdownMenu dir="rtl">
          <DropdownMenuTrigger asChild className={cn(!!onEditBank ? '' : 'hidden')}>
            <Button disabled={!!!onEditBank} variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreVertical className="!m-0 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              onClick={() => onEditBank(bank.id)}
            >
              <UserPenIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">مشاهده و ویرایش</span>
            </DropdownMenuItem>
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              onClick={() => onGetBankDetails(bank.id)}
            >
              <CreditCardIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">مشاهده آمار</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
]
